.areaClockHome{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}
.areaClockHome.bordas{
    border-top:1px solid;
}

.areaClockHome.bordasbtn{
  
}


.areaClockHome .numero{
    width: 25%;
    text-align: center;
    font-size:2vh;
    padding-top: 0.6vh;
    padding-bottom: 0.6vh;
}
.areaClockHome .texto{
    width: 25%;
    text-align: center;
    font-size:1vh;
    padding: 0.5vh 0.1vh 0.5vh 0.1vh;
}
.areaClockHome .numero.divis{
    border-right-style: solid;
    border-right-width: 1px;
}
