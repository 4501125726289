.areaClock{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f5f5f5;
}
.areaClock.bordas{
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.areaClock.bordasbtn{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.numero{
    width: 25%;
    text-align: center;
    font-size:3vh;
}
.texto{
    width: 25%;
    text-align: center;
    font-size:1.2vh;
    padding:0.1vh 0.1vh 0.5vh 0.1vh;
}
.numero.divis{
    border-right-style: solid;
    border-right-width: 1px;
}
