.areaLogin{
    margin-bottom: 5vh;
}

.areaLogin h3{
    color:rgba(211,116,39, 0.9);
}

.areaLogin .btnToLogin{
    background-color: rgba(211,116,39, 0.8);
    border:1px solid rgba(211,116,39, 0.9);
}

.areaLogin .btnToLogin:hover{
    background-color: rgba(211,116,39, 1);
    border:1px solid rgba(211,116,39, 0.8);
}

.areaLogin a{
    color:rgba(211,116,39, 0.8);
    text-decoration: none;
}
.areaLogin a:hover{
    color:rgba(211,116,39, 1);
    text-decoration: none;
}