
.areaBtnLogin{
    margin-top: 1.3vh;
    margin-bottom: 1vh;
    font-size: 11px;
}
.areaBtnLogin:hover>.btnLogin{
    background-color: #fff;
}
.areaBtnLogin a:hover{
    color:orange;
    text-decoration: none;
}

.btnLogin{
    background-color: #5f5f5f;
    border-radius: 5px;
    padding: 1vh 1.5vh 1.3vh 1.5vh;
    color:#fff;
    height: 5vh;
}
.btnLogin span{
    margin-left:1vh;
}