
.mobile{
   
   
}
.mobile.aberto{
    width: 70vw;
    height: 100vh;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;

}
.menu-content{
    background-color: #444;
}

.menu{
    background-color: #444;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
}
.menu div{
    
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 50px;
}
.menu div a{
    color:#fff;
}
.menu div a:hover{
    color:rgb(211,116,39);
    text-decoration: none;
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}