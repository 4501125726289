
*, body{
    margin:0;
    padding:0;
    font-family: 'Roboto Mono', monospace;
   
}
a{
    text-decoration: none;
}
a:hover{
    text-decoration: none;
    color:red;
}
.barra{
    text-align: center;
    color:#fff;
    font-size:1.4vh;
    padding-top:0.7vh;
    padding-bottom: 0.7vh;
}
.header{
    padding-top:1.5vh;
    padding-bottom: 1.5vh;
}
.header .areaLogo{ padding-top: 2vh; padding-bottom: 1vh;}

.header .eusou{ height: 5.5vh; margin-right: 1.5vh;}
.header .arca{ height: 4.5vh; margin-right: 1.5vh;}
.header .rotary{ height: 4.5vh; }



.menu{
    background-color: #e6e6e6;
}

.footer{
    margin-top:2vh;
    background-color: ##f5f5f5;
    border-top:2vh solid rgb(211,116,39);
}

.footer img{
    max-width: 93%;
}


.header .areaName{
    padding-left:12.33%;
    padding-top: 0.8%;
}
.header .areaBuscar{ 
    background-color: #fff;
    border-radius: 1vh;
    margin:2vh 0 0 0;
    padding: 0;
}
.header .areaBuscar input[type="text"]{
    border:0;
    font-size: 14px;
    padding-top: 13px;
    color:#5f5f5f;
    font-weight: bold;
}
.header .areaBuscar input[type="text"]:focus{
    box-shadow: none;
}

.header .btnSearch{
    background-color: #5f5f5f;
    border:1px solid #5f5f5f;
    border-radius: 0.9vh;
}

.header .btnSearch:hover, 
.header .btnSearch:active,
.header .btnSearch:focus{
    background-color: #333;
    border:1px solid #333;
    border-radius: 0.9vh;
    color:orange;
}


/* mobile */
@media only screen and (max-width: 500px) {
    .header .areaLogo{
        text-align: center;
        
    }
    .header .areaName{
        margin-top: 2vh;
        padding-left:2%;
    }
    .areaBtnLogin span{
        display: none;
    }
    .footer{
        text-align: center;
    }
    .footer div{
        text-align: left;
    }
    .footer img{
        max-width: 90vw;
    }
    

    .header .areaBuscar{ }
    .header .areaLogo{ justify-content: space-between; }
    .header .eusou{ height: 4.7vh;  }
    .header .arca{  height: 4.2vh;  }
    .header .rotary{ height: 4.2vh; }
}

