.carousel_caption{
    width: auto;
    left:0;
    bottom:0%;
    text-align: left;
    padding:0;
   
}

.carousel_item{
    text-align: center;
    border:1px solid #f1e2e2;
    border-radius: 5px;
    

}
.carousel_item div{
    width: 100%;
    padding-bottom: 70%;
    background-size: contain;
    height: 70vh;
}

.carousel_item div p{
    background-color: rgba(0,0,0,0.3);
    text-align: left;
    padding-left: 2%;
    color:#fff;
    font-size: 1.3vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
}


.carousel span.carousel-control-next-icon, .carousel span.carousel-control-prev-icon{ 
    
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    filter: invert(75%);
    
}